import axios, { AxiosError } from "axios";
import { persistor, store } from "src/store";
import { resetAuthState } from "../auth/authSlice";

const CSRF_HEADER_NAME = "X-CSRFToken";
const CSRF_COOKIE_NAME = "csrftoken";
const REQUESTED_WITH = "XMLHttpRequest";
const CONTENT_TYPE = "application/json";
const ACCEPT = "application/json";
const MAINTENANCE_REDIRECT_URL = "/maintenance";

const axiosClient = axios.create({
  baseURL: window.location.origin,
});

axiosClient.defaults.xsrfHeaderName = CSRF_HEADER_NAME;
axiosClient.defaults.xsrfCookieName = CSRF_COOKIE_NAME;
axiosClient.defaults.withCredentials = true;
axiosClient.defaults.headers.common["X-Requested-With"] = REQUESTED_WITH;
axiosClient.defaults.headers.common["Content-Type"] = CONTENT_TYPE;
axiosClient.defaults.headers.common["Accept"] = ACCEPT;

// Error Handling Function
const handleErrors = (error: AxiosError) => {
  if (error?.response && error?.response?.status) {
    switch (error?.response?.status) {
      case 502: // Bad Gateway error (site maintenance)
        window.location.href = MAINTENANCE_REDIRECT_URL;
        break;

      case 401: // Unauthorized error (user not logged in)
        const authState = store.getState().auth;
        if (authState.isAuthenticated) {
          store.dispatch(resetAuthState());
          persistor.purge();
          window.location.href = "/";
        }
        break;
      case 403: // Forbidden error (wrong subdomain)
        if (error.response.data.subdomain) {
          const subdomain = error.response.data.subdomain;
          const url =
            process.env.NODE_ENV === "production"
              ? `https://${subdomain}.clearpath.site${window.location.pathname}`
              : `http://${subdomain}.localhost${window.location.pathname}`;

          // Redirect the user to the correct subdomain
          window.location.href = url;

          // Return Promise.reject to skip the next catch block
          return Promise.reject(error);
        }
        break;
      // TODO: Add additional cases for different HTTP statuses
      default:
        console.error(`An error occurred: ${error}`);
    }
  } else {
    console.error(`An error occurred: ${error}`);
  }
  return Promise.reject(error);
};

axiosClient.interceptors.response.use((response) => response, handleErrors);

export default axiosClient;
